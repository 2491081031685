import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

function Header() {
    return (
        <header className="header">
            <div className="logo">
                <img src="/Zodiac.png" alt="Zodiac Panel Logo" />
            </div>
            <nav className="nav-links">
                <Link to="http://zodiacpanel.com/login">Login</Link>
                <Link to="/contact">Contact Us</Link>
            </nav>
        </header>
    );
}

export default Header;