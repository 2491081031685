import React from 'react';
import './Login.css';

function Login() {
    return (
        <div className="login">
            <h2>Login</h2>
            <form>
                <div className="form-group">
                    <label>ID:</label>
                    <input type="text" name="id" required />
                </div>
                <div className="form-group">
                    <label>Password:</label>
                    <input type="password" name="password" required />
                </div>
                <button type="submit" className="submit-btn">Submit</button>
            </form>
        </div>
    );
}

export default Login;
