import React from 'react';
import './ContactUs.css';

function ContactUs() {

    return (
        <div className="contact-us">
            <h2>Contact Us</h2>
            <p>Phone: +2 01028760302</p>
            <p>Email: volcano2024voiceandsms@gmail.com</p>
            <p>Manager Contact: +201028760302</p>
        </div>
    );
}

export default ContactUs;
