import React from 'react';
import './AboutUs.css';

function AboutUs() {
    return (
        <main className="about-us">
            <h1>Welcome to Zodiac Telecom</h1>
            <p>We connect with companies worldwide. Contact us any day—we're ready and waiting for you. Connect with us every day!</p>

            <h2>About Zodiac Telecom</h2>
            <p >Founded in Feb 2023 in Egypt, Zodiac Panel was created to simplify the connection between providers and customers.</p>
            <p>We specialize in SMS and voice traffic monitoring, combining versatile technology with a commitment to quality, ensuring the highest standard of service.</p>

            <h2>Our Services</h2>
            <ul>
                <li>VOIP & Voice: High-quality voice solutions that keep you connected.</li>
                <li>SMS: A versatile tool for multichannel communication, including one-time passwords (OTP) and more.</li>
            </ul>

            <h2>Careers</h2>
            <p>We are always on the lookout for professionals with expertise in large-scale communication companies and providers...</p>
        </main>
    );
}

export default AboutUs;
