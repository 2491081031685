import React from 'react';
import './Footer.css';

function Footer() {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div>
                    <p>Zodiac Panel Headquarters</p>
                    <p>Egypt, Sharkya, Zagazig</p>
                    <p>Skype Manager: live:.cid.fb55b45f9691f599
                    </p>
                    <p>Skype Support: live:.cid.ef4db19f87130be5
                    </p>
                </div>
                <div>
                    <p>Email: volcano2024voiceandsms@gmail.com</p>
                    <p>Manager Contact: +201028760302</p>
                    <p>Phone: +2 01028760302</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
